<template>
  <div class="login">
    <div class="form_warp">
      <div class="left_img">
        <img src="@/assets/login/ca.png"
             alt="">
      </div>
      <div class="right_form">
        <div class="title">
          天天进步中考智能学伴总后台
        </div>
        <div class="form">
          <div class="warp">
            <div class="line">
              <el-input clearable
                        placeholder="请输入您的账号"
                        @keyup.enter.native="login"
                        v-model="form.username">
                <img slot="prefix"
                     src="@/assets/login/icon-账号2.png"
                     alt="">
              </el-input>
            </div>
            <div class="line"
                 style="margin-top:3.7%">
              <el-input clearable
                        placeholder="请输入您的密码"
                        @keyup.enter.native="login"
                        v-model="form.password"
                        type="password">
                <img slot="prefix"
                     src="@/assets/login/icon-密码2.png"
                     alt="">
              </el-input>
            </div>
            <div class="btn"
                 :loading="isLoading"
                 @click="login">
              登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import property from './property'
import opertion from './opertion'
export default {
  name: 'login',
  mixins: [property, opertion]
}
</script>

<style lang="scss"  src="./scss/index.scss" scoped>
</style>