export default {
  created () {
    // this.automaticLogin()
  },
  mounted () {
    // 如果有token 就直接进入home页面
    if (window.localStorage.getItem('userInfo')) {
      if (JSON.parse(window.localStorage.getItem('userInfo')).token) {
        let data = JSON.parse(window.localStorage.getItem('userInfo'))
        this.$router.push('/index')
        // this.$notify({
        //   title: '提示',
        //   message: '您已经登录，已经为您跳转主页!',
        //   type: 'success'
        // })
      }
    }
  },
  methods: {
    login () {
      this.isLoading = !this.isLoading
      this.$http({
        url: '/api/v2/login',
        method: 'post',
        data: this.form
      }).then(res => {
        this.isLoading = !this.isLoading
        let { data } = res
        window.localStorage.setItem('userName', data.admin_name)
        window.localStorage.setItem('userInfo', JSON.stringify(data))

        this.$router.push('/index')
        this.$notify({
          title: '提示',
          message: '登录成功',
          type: 'success'
        });
      })
    }
  }
}